/* eslint-disable camelcase */

import React, { useState } from 'react'
import { bounceIn } from 'react-animations'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import styled, { keyframes } from 'styled-components'
import AlertDynamic from '../../../components/AlertDynamic'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'
import { useInviteCompany } from '../../../utils/api'
/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */

// animations
const BounceInAnimation = keyframes`${bounceIn}`
const Bounce = styled.div`
  animation: 5s ${BounceInAnimation};
  --animate-repeat: 5;
`

const Page = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP } = props

  // states
  const [companyInfo, setCompanyInfo] = useState({
    companyName: '',
    companyEmail: ''
  })
  const [emailInvaild, setEmailInvalid] = useState(false)
  const [update, setUpdate] = useState(0)
  // functions

  // react-query api
  const InviteCompany = useInviteCompany()
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      const submitInfo = {
        nickName: companyInfo.companyName,
        email: companyInfo.companyEmail
      }
      if (validateEmail(submitInfo.email)) {
        setEmailInvalid(false)
        InviteCompany.mutate({ invitedCompany: submitInfo })
      } else {
        setEmailInvalid(true)
      }
    }
  }
  const onHide = () => {
    setOpenModal(false)
    InviteCompany.reset()
    setEmailInvalid(false)
    setCompanyInfo({
      companyName: '',
      companyEmail: ''
    })
  }
  const inviteNewCompany = (e) => {
    e.preventDefault()
    setUpdate(update + 1)
    InviteCompany.reset()
    if (companyInfo.companyName.length !== 0 && companyInfo.companyEmail.length !== 0) {
      const submitInfo = {
        nickName: companyInfo.companyName,
        email: companyInfo.companyEmail
      }
      if (validateEmail(submitInfo.email)) {
        setEmailInvalid(false)
        InviteCompany.mutate({ invitedCompany: submitInfo })
      } else {
        setEmailInvalid(true)
      }
    }
  }

  return (
    <>
      <Modal show={openModal} onHide={onHide}>
        <Modal.Body>
          <button type="button" className="close" onClick={onHide}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <h5 className="m-0 mb-3">Invite a new company</h5>
          <p>Invite a user to register their company.</p>

          <form>
            <Form.Group>
              <Form.Label>Company nickname</Form.Label>
              <Form.Control
                className="custom-input"
                type="name"
                placeholder="Company nickname"
                name="company-name"
                autoFocus={true}
                value={companyInfo.companyName}
                onChange={(e) =>
                  setCompanyInfo((ps) => ({
                    ...ps,
                    companyName: e.target.value
                  }))
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
              className="custom-input"
                type="email"
                autoComplete="email"
                placeholder="Email address"
                name="company-mail"
                onKeyDown={onKeyDown}
                value={companyInfo.companyEmail}
                onChange={(e) =>
                  setCompanyInfo((ps) => ({
                    ...ps,
                    companyEmail: e.target.value
                  }))
                }
              />
            </Form.Group>
          </form>

          {/* buttons */}
          <div className="d-flex justify-content-between mt-5">
            <Button
              type="button"
              onClick={onHide}
              variant="outline-primary"
              style={{ border: 0 + 'px' }}
            >
            {InviteCompany.isSuccess ? 'Close' : 'Cancel'}
            </Button>
            <Button
              type="button"
              onClick={(e) => {
                inviteNewCompany(e)
              }}
              variant="primary"
              disabled={companyInfo.companyName.length === 0 || companyInfo.companyEmail.length === 0}
            >
            {InviteCompany.isLoading
              ? (
              <Spinner />
                )
              : (
              <span>Invite</span>
                )}
            </Button>
          </div>

          {emailInvaild && (
            <AlertDynamic key={update} msg={'Email invalid'}></AlertDynamic>
          )}

            { InviteCompany.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Invite successful!</Alert> }
            { InviteCompany.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error: ' + InviteCompany.error.message}</Alert> }
          {/* { process.env.NODE_ENV !== 'production' && <Button className="mt-3" onClick={() => clearFile()}>Clear File</Button>} */}
        </Modal.Body>
        {/* {process.env.NODE_ENV !== 'production' && (<pre>companiesQuery={JSON.stringify(companyInfo, null, 2)}</pre>)} */}
      </Modal>

      {/* {process.env.NODE_ENV !== 'production' && <pre>listingLotSkus={JSON.stringify(listingLotSkus, null, 2)}</pre>} */}
    </>
  )
}

export default Page
